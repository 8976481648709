import styled from 'styled-components'

const DownArrowDiv = styled.div`
	position: absolute;
	left: 50%;
	top: 100%;
	width: 10%;
	max-width: 60px;
	transform: translate(-50%, -100%);
	cursor: pointer;

	svg {
		width: 100%;
		position: relative;
		top: -20px;

		path {
			transform-origin: 10px 10px;
		}

		path:first-child {
			stroke: #000;
			stroke-width: 18;
			stroke-linecap: round;
		}

		path:last-child {
			stroke: #fff;
			stroke-width: 6;
			stroke-linecap: round;
		}
	}
`

const DownArrow = (props)=>{

	return (
		<DownArrowDiv
			onClick={()=>document.getElementsByClassName('screen')[1].scrollIntoView()}
			role="downarrow-svg"
		>
			<svg viewBox="0 0 98 62" fill="none">
				<path d="M 10.412 10.711 L 48.412 48.711 L 86.412 10.711"/>
				<path d="M 10.412 10.711 L 48.412 48.711 L 86.412 10.711"/>
			</svg>
		</DownArrowDiv>
	)
	
}

export default DownArrow
