import Screen from "../Screen"
import DownArrow from "./home/DownArrow"
import Logo from "./home/Logo"
import PreviewVideo from "./home/PreviewVideo"

const Home = (props)=>{

	return (
		<Screen>
			<Logo />
			<PreviewVideo />
			<DownArrow />
		</Screen>
	)
	
}

export default Home