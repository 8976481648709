import { styled } from 'styled-components'

const ScreenDiv = styled.div`
	position: relative;
	width: 100vw;
	height: 100vh;
	height: 100dvh;
`

const Screen = (props)=>{

	return (
		<ScreenDiv 
			className="screen"
			style={props.style}>
			{props.children}
		</ScreenDiv>
	)
	
}

export default Screen
