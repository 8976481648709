import { useEffect, useState } from 'react'
import Home from './screens/Home'
import Gigs from './screens/Gigs'
import Contact from './screens/Contact'

const Site = (props)=>{

	return <div>
		<Home />
		{/* <Gigs />
		<Contact /> */}
	</div>

}

export default Site
