import Site from './Site'

const App = (props)=>{

	return (
		<Site />
	)
	
}

export default App
