import styled from 'styled-components'

import Screen from "../Screen"
import FundraiserSrc from '../../../img/school-fundraiser.jpg'

const GigsDiv = styled.div`
	height: 100%; 
	width: 100%;
	background-color: #000;

	a {
		height: 100%;
		display: inline-block;
		left: 50%;
		position: relative;
		transform: translate(-50%, 0);

		img {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
`

const Gigs = (props)=>{

	return (
		<Screen>
			<GigsDiv>
				<a href="https://pay.collctiv.com/paperjam-fundraiser-57429" target='_blank'>
					<img src={FundraiserSrc} />
				</a>
			</GigsDiv>
		</Screen>
	)
	
}

export default Gigs