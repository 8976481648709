import Screen from "../Screen"

const Contact = (props)=>{

	return (
		<Screen>
			Contact		
		</Screen>
	)
	
}

export default Contact