import styled from 'styled-components'

const PreviewVideoSrc = new URL('../../../../video/preview.mp4', import.meta.url)

const Video = styled.video`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const PreviewVideo = (props)=>{

	return (
		<Video 
			src={PreviewVideoSrc}
			autoPlay={true}
			muted={true}
			loop={true}
			playsInline={true}
		></Video>
	)
	
}

export default PreviewVideo